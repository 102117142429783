var React = require('react');

function Footer() {
  return (
    <div>
      <footer className="bg-white min-w-full">
        <nav className="flex mx-auto p-5 text-xs">
          <p className="flex-1 text-black font-sans-serif text-center content-center">
            Designed and Developed for my <span role= "img" aria-label="heart emoji">❤️</span> by{" "}
            <a
              href="https://www.justintannenbaum.com"
              className="font-bold no-underline text-black"
            >
              Justin Tannenbaum
            </a>
          </p>
        </nav>
      </footer>
    </div>
  );
}

export default Footer;