var React = require('react');

function Banner() {
  return (
    <banner className="flex">
      <div class="min-w-full min-h-8 bg-red-700">
        <div class="font-sans-serif mt-2 mb-2 text-center align-middle text-white">
         <a href="https://theroyaltannenbaums.com/announcement" class="ml-1 underline">An update from Betsy and Justin regarding postponing our wedding due to COVID-19</a>
        </div>
      </div>
    </banner>
  );
}

export default Banner;