import { graphql, useStaticQuery, Link } from "gatsby";
import React, { useState } from "react";

function Header() {
  const [isExpanded, toggleExpansion] = useState(false);

  return (
    <header className="bg-white">
      <div className="ml-2 mr-2 flex flex-wrap items-center justify-between  max-w-screen mx-auto mt-2 mb-2 p-4 md:p-2">
        <Link className="flex items-center no-underline text-black" to="/">
          <span className="ml-2 text-black font-cursive text-4xl">
            Betsy and Justin
          </span>
        </Link>

        <button
          className="block md:hidden border border-white flex items-center px-3 py-2 rounded text-black"
          onClick={() => toggleExpansion(!isExpanded)}
        >
          <svg
            className="fill-current h-6 w-6"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title class="text-black">Menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
        </button>

        <nav
          className={`${
            isExpanded ? `block` : `hidden`
          } md:block md:flex md:items-center w-full md:w-auto`}
        >
          <Link
              to="/#our-story"
              className="block mt-4 md:mt-0 ml-4 mr-4 rounded hover:bg-gray-200 no-underline text-black font-sans-serif"
            >
              OUR STORY
            </Link>

            <Link
              to="/#details"
              className="block mt-4 md:mt-0 ml-4 mr-4 rounded hover:bg-gray-200 no-underline text-black font-sans-serif"
            >
              DETAILS
            </Link>
          {[
            {
              route: `/accommodations`,
              title: `ACCOMODATIONS`
            },
            {
              route: `/registry`,
              title: `REGISTRY`
            },
            {
              route: `/gallery`,
              title: `GALLERY`
            }
          ].map(link => (
            <Link
              className="block md:inline-block mt-4 ml-4 md:mt-0 md:ml-6 no-underline text-black font-sans-serif rounded hover:bg-gray-200"
              key={link.title}
              to={link.route}
            >
              {link.title}
            </Link>
          ))}
          <a 
            href="mailto:bets.appleton@gmail.com"
            className="block mt-4 md:mt-0 ml-4 mr-6 rounded hover:bg-gray-200 no-underline text-black font-sans-serif"
          >
            CONTACT
          </a>
        </nav>
      </div>
    </header>
  );
}

export default Header;